import * as React from "react";
import { useState, useRef, useEffect } from "react";
import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import vtkFullScreenRenderWindow from "./FullScreenRenderWindow";
import vtkRenderWindowInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";
// import vtkGenericRenderWindow from '@kitware/vtk.js/Rendering/Misc/GenericRenderWindow.js';
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";

import vtkOBJReader from "@kitware/vtk.js/IO/Misc/OBJReader";
import vtkMTLReader from "@kitware/vtk.js/IO/Misc/MTLReader";
import vtkPicker from "@kitware/vtk.js/Rendering/Core/Picker";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import vtkOpenGLRenderWindow from "@kitware/vtk.js/Rendering/OpenGL/RenderWindow";

import axios from "axios";

import { useParams, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";

// import { hostname, port } from './config';
import { CircularProgress, Icon, Modal } from "@mui/material";
import { ColorPicker } from "material-ui-color";
import { Button, Select, MenuItem, FormControl } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { set } from "@kitware/vtk.js/macros";
import DeviceOrientation, { Orientation } from "react-screen-orientation";

import { inferURLs, apiURLs } from "./config";

const styles = {
  modal: {
    width: "500px",
    height: "100px",
    maxHeight: "500px",
    background: "#fff",
    opacity: 1,
    position: "absolute",
    transform: "translate(-50%, -50%)",
    margin: "0",
    padding: "20px",
    top: "10%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'center',
    alignItems: "center",
    border: "1px solid #000",
    borderRadius: "5px",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    // alignItems: 'center',
    background: "#000",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  bodyWrapper: (isLandscape) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    // alignItems: 'center',
    background: "#000",
    flexDirection: isLandscape ? "row" : "column",
    justifyContent: "space-between",
  }),
  optionsWrapper: (open) => ({
    minWidth: "200px",
    maxWidth: "600px",
    // height: '100%',
    display: open ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "start",
    zIndex: "1",
  }),
  vtkContainer: (loaded) => ({
    // width: '80%',
    // height: '100%',
    height: "80vh",
    width: "100vw",
    // display: loaded ? 'block' : 'none',
  }),
  options: {
    padding: "5px",
    width: "100%",
    display: "flex",
    // height: '100%',
    flexDirection: "column",
    // alignItems: 'center',
    justifyContent: "center",
  },
  option: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    minWidth: "50px",
    height: "28px",
  },
  segmentName: (color) => ({
    flex: 1,
    fontSize: "12pt",
    color: color,
    fontWeight: "bold",
    textAlign: "left",
  }),
  segmentNameWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "2px",
  },
  modelSelect: {
    background: "#bbb",
    padding: "10px",
    margin: "5px",
    width: "120px",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid #000",
  },
  configSelect: {
    background: "#bbb",
    padding: "10px",
    width: "120px",
    height: "40px",
    margin: "5px",
    borderRadius: "5px",
    border: "1px solid #000",
  },
  visibilityEye: (color) => ({
    // paddingLeft: '5px',
    // paddingRight: '10px',
    fill: color,
    color: color,
    cursor: "pointer",
  }),
  visibilityEyeWrapper: {
    height: "100%",
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  optionsButton: {
    width: "40px",
    height: "40px",
    padding: "10px",
    marginLeft: "20px",
  },
  tableItem: {
    // width: '100%',
    paddingLeft: "5px",
    paddingRight: "5px",
  },
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const colorArrayToHexString = (colorArray) => {
  // console.log(colorArrayToHexString, 'colorArrayToHexString');
  return (
    "#" +
    colorArray
      .map((x) => {
        return ("0" + Math.ceil(x * 255).toString(16)).slice(-2);
      })
      .join("")
  );
};

const colorHexToArray = (colorHex) => {
  return colorHex
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
};

const comparePoints = (a, b) => {
  if (
    Math.abs(a.x - b.x) < 0.01 &&
    Math.abs(a.y - b.y) < 0.01 &&
    Math.abs(a.z - b.z) < 0.01
  ) {
    return true;
  }
  return false;
};

const VisibilityEye = (props) => {
  const [visible, setVisible] = React.useState(true);
  if (visible) {
    return (
      <div style={styles.visibilityEyeWrapper}>
        <VisibilityIcon
          style={styles.visibilityEye(props.color)}
          key={props.key}
          onClick={() => {
            setVisible(false);
            props.onVisibilityChange(props.name, false);
          }}
        />
      </div>
    );
  } else {
    return (
      <div style={styles.visibilityEyeWrapper}>
        <VisibilityOffIcon
          style={styles.visibilityEye(props.color)}
          key={props.key}
          onClick={() => {
            setVisible(true);
            props.onVisibilityChange(props.name, true);
          }}
        />
      </div>
    );
  }
};
export default function Render3D(props) {
  const { centerName, jobId, accessId } = useParams();
  // const location = useLocation();
  const vtkContainerRef = React.useRef(null);
  const context = React.useRef(null);
  const [scene, setScene] = React.useState([]);
  const [model, setModel] = React.useState(null);
  const [models, setModels] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [loadedOnce, setLoadedOnce] = React.useState(false);
  const [fullScreenRenderer, setFullScreenRenderer] = React.useState(null);
  const [objReaders, setObjReaders] = React.useState({});
  const [mtlReaders, setMtlReaders] = React.useState({});
  const [optionsDrawerOpen, setOptionsDrawerOpen] = React.useState(false);
  const [windowInteractor, setWindowInteractor] = React.useState(null);
  const [pointPicker, setPointPicker] = React.useState(null);
  const [modelOptions, setModelOptions] = React.useState({});
  const [stoneColours, setStoneColours] = React.useState({});
  const [stonesData, setStonesData] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState(false);
  const [stoneSelected, setStoneSelected] = React.useState(null);
  const [position, setPosition] = React.useState({ x: 0, y: 0, z: 0 });
  const [point, setPoint] = React.useState({ x: 0, y: 0, z: 0 });
  const [eventsSet, setEventsSet] = React.useState(false);
  const [patientName, setPatientName] = React.useState("");

  const [configs, setConfigs] = React.useState([]);
  const [config, setConfig] = React.useState("");

  const [baseURL, setBaseURL] = useState("");
  const [baseURLAPI, setBaseURLAPI] = useState("");
  const [center, setCenter] = useState("");
  const [admin, setAdmin] = useState(false);
  const [mode, setMode] = useState();
  const [loggedIn, setLoggedIn] = useState(true);

  const [isRotating, setIsRotating] = useState(true);
  const [renderer, setRenderer] = useState(null);

  const token = admin
    ? localStorage.getItem(`token-${center}`)
    : localStorage.getItem("token");

  // console.log('token', token, 'center', center, centerName, 'admin', admin);

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const isLandscape = dimensions.width > dimensions.height;
  // console.log(dimensions, 'dimensions', isLandscape, 'isLandscape');

  React.useEffect(() => {
    console.log("getting center and admin");
    // get center from local storage
    let center = localStorage.getItem("center");
    let admin = localStorage.getItem("admin");
    // check if mode is set in local storage
    const mode = localStorage.getItem("mode") || "dark";

    if ((!center || !admin) && accessId !== undefined) {
      setLoggedIn(false);
    }
    if(centerName){
      center = centerName
    }else{
      center = center;
    }
    admin = admin === "true";

    setCenter(center);
    setAdmin(admin);
    setMode(mode);

    setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);

    if (isLandscape) setOptionsDrawerOpen(true);

    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");

    // axios.get(`${apiURLs[center]}/configs?job_id=${jobId}`, {
    let configsUrl;
    if (accessId !== undefined) {
      configsUrl = `//inference4.urologiq.ai/urologiq_test/${center}/api/configs_access_id?access_id=${accessId}&job_id=${jobId}`;
      axios
        .get(configsUrl)
        .then((res) => {
          setConfigs(res.data.configs);
          setConfig(res.data.configs[res.data.configs.length - 1]);
        });
    } else {
      configsUrl = `${apiURLs[center]}/configs?job_id=${jobId}`;
      axios
        .get(configsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConfigs(res.data.configs);
          setConfig(res.data.configs[res.data.configs.length - 1]);
        });
    }
  }, []);

  React.useEffect(() => {
    if (baseURLAPI !== "" && config !== "") {
      let modelPath;
      modelPath = `${baseURLAPI}/models`;
      console.log("calling api");

      axios
        .get(modelPath)
        .then((res) => {
          console.log("models", res);
          setModels(res.data.models);
          setModel(res.data.models[0]);
          if (!loadedOnce) {
            // if(vtkFullScreenRenderWindow !== null)
            setFullScreenRenderer(
              vtkFullScreenRenderWindow.newInstance({
                rootContainer: vtkContainerRef.current,
                background: [0, 0, 0], //[220, 220, 220]
              })
            );
            setWindowInteractor(vtkRenderWindowInteractor.newInstance());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [baseURLAPI, config]);

  React.useEffect(() => {
    console.log("models", models, baseURLAPI, config);
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    // if(models.filter(model => model.startsWith('stone')).length > 0)
    if (baseURLAPI !== "") {
      let jsonUrl;
      if (accessId !== undefined)
        jsonUrl = `//inference4.urologiq.ai/urologiq_test/${center}/api/json_access_id?access_id=${accessId}&job_id=${jobId}&config=${config}`;
      else jsonUrl = `${baseURLAPI}/json?job_id=${jobId}&config=${config}`;
      axios
        .get(jsonUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("stones", res);
          let stones = {};
          for (let i = 0; i < res.data.stones.length; i++) {
            stones[`stone_${i + 1}`] = res.data.stones[i];
          }
          setStonesData(stones);
          setPatientName(res.data.patient.name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [config]);

  React.useEffect(() => {
    if (loaded && fullScreenRenderer !== null && !eventsSet) {
      console.log("setting up new events");
      // console.log(fullScreenRenderer.getRenderer());
      const interactor = fullScreenRenderer.getRenderWindow().getInteractor();

      interactor.onMouseEnter((e) => {
        // console.log('mouse enter!!', e);
      });
      interactor.onMouseLeave((e) => {
        // console.log('mouse leave!!', e);
      });
      interactor.onMouseMove((e) => {
        // const point = [ position.x, position.y, position.z ];

        const point = [e.position.x, e.position.y, 0];
        // console.log('end mouse move!!', e, interactor, position, point);
        const pointPicker = vtkPicker.newInstance({
          renderer: fullScreenRenderer.getRenderer(),
          // pickFromList: [point],
          tolerance: 0.01,
        });
        pointPicker.pick(point, fullScreenRenderer.getRenderer());
        // console.log(pointPicker.getActors(), 'actors');
        let newSceneList = {};
        for (let i = 0; i < pointPicker.getActors().length; i++) {
          const actor = pointPicker.getActors()[i];
          const name = actor.get("name").name;
          if (name.toLowerCase().startsWith("stone")) {
            // actor.getProperty().setOpacity(1.0);
            console.log("chaging color: ", name);
            actor.getProperty().setDiffuseColor(1.0, 0.0, 0.0);
            // actor.getProperty().setAmbientColor(0.0, 0.0, 0.0);
            // actor.getProperty().setSpecularColor(0.0, 0.0, 0.0);
            newSceneList[name] = actor;
          }
        }
        if (Object.keys(newSceneList).length > 0) {
          // console.log('length > 0');
          setScene((oldScene) => {
            return oldScene.map((x) => {
              if (x.name in newSceneList) {
                return {
                  ...x,
                  actor: newSceneList[x.name],
                };
              } else {
                return x;
              }
            });
          });
          setModalOpen(true);
          setStoneSelected(Object.keys(newSceneList)[0]);
          context.current.renderWindow.render();
        } else {
          setScene((oldScene) => {
            // console.log('changing colour back...', stoneColours);
            return oldScene.map((x) => {
              if (
                x.name.toLowerCase().startsWith("stone") &&
                x.name in stoneColours
              ) {
                // console.log('hi', x.name);
                // x.actor.getProperty().setOpacity(0.7);
                // x.actor.getProperty().setDiffuseColor(1.0, 1.0, 1.0);
                const [r, g, b] = stoneColours[x.name];
                x.actor.getProperty().setDiffuseColor(r, g, b);
                x.actor.getProperty().setAmbientColor(0, 0, 0);
                x.actor.getProperty().setSpecularColor(0, 0, 0);
                return x;
              } else {
                return x;
              }
            });
          });
          setModalOpen(false);
          context.current.renderWindow.render();
        }
        // console.log(pointPicker.pick(interactor.getEventPosition(), fullScreenRenderer.getRenderer()));
      });
      setEventsSet(true);
    }
  }, [windowInteractor, fullScreenRenderer, loaded, stoneColours]); //, pointPicker]);

  const handleStartRotation = () => {
    setIsRotating(true);
  };

  const handleStopRotation = () => {
    setIsRotating(false);
  };

  React.useEffect(() => {
    const rotateActor = () => {
      if (isRotating && renderer && center == "sahyadri") {
        const camera = renderer.getActiveCamera();
        camera.azimuth(0.5); // Rotate camera by 1 degree
        renderer.resetCameraClippingRange();
        renderer.getRenderWindow().render(); // Render the scene
      }
    };

    const rotationInterval = setInterval(rotateActor, 15); // Adjust rotation speed

    return () => clearInterval(rotationInterval); // Cleanup on unmount or state change
  }, [isRotating, renderer, center]);

  const setData = (data, model_i) => {
    const objReader = vtkOBJReader.newInstance({ splitMode: "usemtl" });
    objReader.parseAsText(data.obj);

    const mtlReader = vtkMTLReader.newInstance();
    mtlReader.parseAsText(data.mtl);

    console.log("image is loaded, rendering....");
    console.log("number of output ports", objReader.getNumberOfOutputPorts());
    const renderer = fullScreenRenderer.getRenderer();
    const renderWindow = fullScreenRenderer.getRenderWindow();
    console.log("actors", renderer.getActors());

    let sceneList = [];
    let actors = [];
    let mappers = [];
    for (let i = 0; i < objReader.getNumberOfOutputPorts(); i++) {
      const polydata = objReader.getOutputData(i);
      const name = polydata.get("name").name;
      // if(scene.filter(s => s.name === name).length === 0) {

      const mapper = vtkMapper.newInstance();
      mapper.setInputData(polydata);
      const actor = vtkActor.newInstance();
      actor.set({ name: name });
      actor.setMapper(mapper);
      mtlReader.applyMaterialToActor(name, actor);
      // if actor exists remove it
      const oldActor = renderer
        .getActors()
        .find((a) => a.get("name").name === name);
      if (oldActor) {
        console.log("removing old actor", oldActor);
        renderer.removeActor(oldActor);
      }
      renderer.addActor(actor);
      console.log(
        "name",
        name,
        actor,
        actor.getOrigin(),
        actor.getOrientation()
      );
      actor.setOrientation(120, 90, 0);
      // actor.addPosition([100, 10000, 100]);
      // actor.setOrigin([0, 500, 0]);
      // actor.setOrigin(0, -500, 0);
      // translate actor
      // const transform = vtkMatrixBuilder.buildFromDegree().translate(-5000.0, 0.0, 0.0).scale(0.5, 0.5, 0.5).getMatrix();
      // actor.setUserMatrix(transform);

      console.log(
        "actor",
        actor,
        actor.getPosition(),
        actor.getOrigin(),
        actor.getOrientation(),
        actor.getScale(),
        actor.getUserMatrix()
      );
      if (model_i.toLowerCase().startsWith("stone")) {
        actor.getProperty().setOpacity(1.0);
        // actor.getProperty().setDiffuseColor(0.0, 0.0, 1.0);
        actor.getProperty().setDiffuseColor(1.0, 1.0, 1.0);
        // actor.getProperty().setSpecularColor(0.8, 0.8, 0.8);
        setStoneColours((stoneColours) => {
          return { ...stoneColours, [name]: [1.0, 1.0, 1.0] };
        });
      } else if (model_i.toLowerCase() === "kub") {
        // actor.getProperty().setOpacity(0.5);
        actor.getProperty().setOpacity(0.3);
      } else {
        // actor.getProperty().setOpacity(0.5);
        actor.getProperty().setOpacity(0.3);
      }
      console.log(actor, "actor");
      // console.log('color: ', actor.getProperty().getDiffuseColor(), actor.getProperty().getAmbientColor(), actor.getProperty().getSpecularColor(), name);
      console.log("color: ", actor.getProperty(), name);
      sceneList.push({ name, actor, mapper });
      actors.push(actor);
      mappers.push(mapper);
      // }
    }
    renderer.resetCamera();
    setRenderer(renderer);
    // add light source
    // const light = vtkLight.newInstance();
    // light.setLightTypeToSceneLight();
    // light.setPositional(true);
    // light.setPosition(-500, -500, 0);
    // light.setFocalPoint(0, 0, 0);
    // light.setConeAngle(0);
    // light.setExponent(1);
    // light.setAmbientColor(1.0, 1.0, 1.0);
    // light.setDiffuseColor(1.0, 1.0, 1.0);
    // light.setSpecularColor(1.0, 1.0, 1.0);
    // renderer.addLight(light);

    // use opengl

    // renderWindow.render();

    const openGLRenderWindow = vtkOpenGLRenderWindow.newInstance();
    renderWindow.addView(openGLRenderWindow);
    renderWindow.render();

    setModelOptions((modelOptions) => {
      return { ...modelOptions, [model_i]: sceneList };
    });

    setScene((prevList) => {
      return [...prevList, ...sceneList];
    });
    console.log("scene", sceneList, model_i);

    context.current = {
      renderWindow,
      renderer,
      actors,
      mappers,
      // coneSource,
    };
  };

  console.log("stonesData", stonesData, stoneSelected);

  React.useEffect(() => {
    console.log("modellll", model, config, fullScreenRenderer);
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    if (model === null || fullScreenRenderer === null) {
      console.log("model or renderer null");
      return;
    }
    if (loadedOnce) {
      setObjReaders({});
      setScene([]);
      // remove all actors from renderer
      context.current.renderer.removeAllViewProps();
      context.current.renderWindow.render();
    }

    for (let model_i of models) {
      let objPath;
      if (accessId !== undefined)
        objPath = `//inference4.urologiq.ai/urologiq_test/${center}/api/get_obj_mtl_access_id?job_id=${jobId}&model=${model_i}&file_type=obj&config=${config}&a.obj&access_id=${accessId}`;
      else
        objPath = `${baseURLAPI}/get_obj_mtl?job_id=${jobId}&model=${model_i}&file_type=obj&config=${config}&a.obj`;
      console.log("objReaders", objReaders, `${model_i}_${config}`);
      if (objReaders[model_i] === undefined) {
        axios
          .get(objPath, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setObjReaders({ ...objReaders, [model_i]: res.data });
            setData(res.data, model_i);
          })
          .catch((err) => {
            console.log("error", err);
          });
      } else {
        setData(objReaders[model_i], model_i);
      }
    }
    // return () => {
    //     console.log('unloading');
    // };
  }, [fullScreenRenderer, config]);

  React.useEffect(() => {
    // console.log('setting loaded', modelOptions, scene);
    if ("kub" in modelOptions && "stone" in modelOptions) {
      setLoaded(true);
      setLoadedOnce(true);
    } else if ("kub_cect" in modelOptions) {
      setLoaded(true);
      setLoadedOnce(true);
    }
  }, [modelOptions, scene]);

  if (!loggedIn && accessId === undefined) {
    return <Navigate to="/login" />;
  }
  // console.log('stonecolours', stoneColours);
  // if(isLandscape)
  return (
    // <DeviceOrientation lockOrientation={'landscape'}>
    //      <Orientation orientation='landscape' alwaysRender={false}>
    <div style={styles.wrapper}>
      <Button
        onClick={() => {
          setOptionsDrawerOpen(!optionsDrawerOpen);
          // reset origin
        }}
        style={styles.optionsButton}
      >
        {/* <MenuIcon
                    style={{ fill: 'white' }}
                /> */}
        {isLandscape ? (
          optionsDrawerOpen ? (
            <KeyboardArrowLeftIcon style={{ fill: "white" }} />
          ) : (
            <KeyboardArrowRightIcon style={{ fill: "white" }} />
          )
        ) : optionsDrawerOpen ? (
          <KeyboardArrowUpIcon style={{ fill: "white" }} />
        ) : (
          <KeyboardArrowDownIcon style={{ fill: "white" }} />
        )}
      </Button>
      <div style={styles.bodyWrapper(isLandscape)}>
        {optionsDrawerOpen ? (
          <div style={styles.optionsWrapper(optionsDrawerOpen)}>
            {/* <p style={{color: 'white', marginLeft: '10px', fontSize: '16pt', fontWeight: '700'}}>{location.state.patientName}</p> */}
            <p
              style={{
                color: "white",
                marginLeft: "5px",
                fontSize: "16pt",
                fontWeight: "700",
              }}
            >
              {patientName}
            </p>
            {admin ? (
              <FormControl style={{ margin: "10px", color: "white" }}>
                Config:
                <Select
                  style={styles.configSelect}
                  value={config}
                  onChange={(e) => {
                    setConfig(e.target.value);
                    setLoaded(false);
                  }}
                >
                  {configs.map((config, index) => {
                    return (
                      <MenuItem key={index} value={config}>
                        {config}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
            <FormControl style={{ margin: "5px", color: "white" }}>
              Model:
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Select
                  style={styles.modelSelect}
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value);
                    console.log("modelOptions", modelOptions, model);
                  }}
                >
                  {models.map((model, index) => {
                    return (
                      <MenuItem key={index} value={model}>
                        {model}
                      </MenuItem>
                    );
                  })}
                </Select>
                <span style={{ marginLeft: "20px" }}>
                  {center == "sahyadri" && (
                    <div>
                      {!isRotating ? (
                        <IconButton
                          title="Start Rotation"
                          style={styles.visibilityEye("#fff")}
                          onClick={() => {
                            handleStartRotation();
                          }}
                        >
                          <SyncIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="Stop Rotation"
                          style={styles.visibilityEye("#fff")}
                          onClick={() => {
                            handleStopRotation();
                          }}
                        >
                          <SyncDisabledIcon />
                        </IconButton>
                      )}
                    </div>
                  )}
                </span>
              </div>
            </FormControl>
            <div style={styles.options}>
              {loaded
                ? modelOptions[model].map(({ name, actor, mapper }) => {
                    let hexColor;
                    // if(model === 'stone' && name in stoneColours)
                    // hexColor = colorArrayToHexString(stoneColours[name]);
                    // else
                    hexColor = colorArrayToHexString(
                      actor.getProperty().getDiffuseColor()
                    );
                    // console.log('hexColor', hexColor);
                    return (
                      <div key={name} style={styles.option}>
                        <div style={styles.segmentNameWrapper}>
                          <span style={styles.segmentName(hexColor)}>
                            {name.toUpperCase()}
                          </span>
                        </div>
                        <VisibilityEye
                          color={hexColor}
                          onVisibilityChange={(name, visible) => {
                            console.log("change visibility", actor);
                            actor.setVisibility(visible);
                            context.current.renderWindow.render();
                          }}
                        />
                        <ColorPicker
                          style={{ flexGrow: 1 }}
                          name={name}
                          value={hexColor}
                          hideTextfield={true}
                          deferred={true}
                          disableAlpha={false}
                          onChange={(color) => {
                            console.log("changed color tooo: ", color);
                            setStoneColours((oldStoneColours) => {
                              let [r, g, b] = color.rgb.map((c) => c / 255);
                              console.log("setting stone colours", r, g, b);
                              return {
                                ...oldStoneColours,
                                [name]: [r, g, b],
                              };
                            });
                            setModelOptions((oldModelOptions) => {
                              return {
                                ...oldModelOptions,
                                [model]: oldModelOptions[model].map((s) => {
                                  if (s.name === name) {
                                    console.log(oldModelOptions);
                                    let [r, g, b] = color.rgb.map(
                                      (c) => c / 255
                                    );
                                    s.actor
                                      .getProperty()
                                      .setDiffuseColor(r, g, b);
                                    s.actor
                                      .getProperty()
                                      .setAmbientColor(0, 0, 0);
                                    s.actor
                                      .getProperty()
                                      .setSpecularColor(0, 0, 0);
                                    s.actor
                                      .getProperty()
                                      .setOpacity(color.alpha);
                                    console.log(windowInteractor);
                                    console.log(
                                      fullScreenRenderer.getRenderer()
                                    );
                                    context.current.renderWindow.render();
                                  }
                                  return s;
                                }),
                              };
                            });
                          }}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}
        <div
          id="vtkContainer"
          ref={vtkContainerRef}
          style={styles.vtkContainer(loaded)}
        />{" "}
        :{!loaded ? <CircularProgress style={{ margin: "20px" }} /> : null}
        {/* <Modal
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
                console.log(stonesData, 'stonesData', stoneSelected);
            }}
        > */}
        {modalOpen ? (
          <div style={styles.modal}>
            <table>
              <thead>
                <tr>
                  <th style={styles.tableItem}>Side</th>
                  <th style={styles.tableItem}>Volume</th>
                  <th style={styles.tableItem}>Size</th>
                  <th style={styles.tableItem}>Location</th>
                  <th style={styles.tableItem}>HU</th>
                </tr>
              </thead>
              <tbody>
                {stoneSelected in stonesData ? (
                  <tr>
                    <td style={styles.tableItem}>
                      {capitalize(stonesData[stoneSelected]["side"])}
                    </td>
                    <td style={styles.tableItem}>
                      {stonesData[stoneSelected]["volume"]}
                    </td>
                    <td style={styles.tableItem}>
                      {stonesData[stoneSelected]["size"]}
                    </td>
                    <td style={styles.tableItem}>
                      {capitalize(stonesData[stoneSelected]["location"])}
                    </td>
                    <td style={styles.tableItem}>
                      {stonesData[stoneSelected]["hu_value"]}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        ) : null}
        {/* </Modal> */}
      </div>
    </div>
    //     </Orientation>
    // </DeviceOrientation>
  );
  // else
  // return null;
}
