import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// import { auth, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import "./Login.css";
// import urologiq logo
import logo from "./urologiq.png";
import videoBg from "./assets/loginBgVid.mp4";
import { TextField, Button, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { apiURLs } from "./config";
import { Navigate } from "react-router-dom";

import Scene from "./ModelViewer";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    overflow: "hidden",
  },
  videoContainer: {
    width: "65%",
    height: "100%",
    flex: "1",
    position: "relative",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "420px",
    width: "400px",
    backgroundColor: "#f5f5f5",
    // borderRadius: "10px",
    // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    // border: "1px solid #e0e0e0",
  },
  email: {
    width: "250px",
    height: "50px",
    marginBottom: "15px",
  },
  password: {
    width: "250px",
    height: "50px",
    marginBottom: "20px",
  },
  center: {
    width: "250px",
    height: "50px",
    marginBottom: "15px",
  },
  loginBtn: {
    backgroundColor: "#1b4b84",
    color: "white",
    width: "220px",
    height: "40px",
    marginBottom: "10px",
  },
  error: {
    color: "red",
    marginTop: "10px",
    fontSize: "12px",
  },
};

export const onLogout = (props) => {
  if (props.admin) {
    const centers = Object.keys(apiURLs);
    console.log("centers", centers);
    for (let i = 0; i < centers.length; i++) {
      // localStorage.setItem(`token-${center}`, data.token);
      localStorage.removeItem(`token-${centers[i]}`);
    }
    localStorage.removeItem("admin");
    localStorage.removeItem("load-labels");
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("admin");
  }
  localStorage.removeItem("admin");
  localStorage.removeItem("center");
  window.location.href = "/dashboard/login";
};

function Login(props) {
  const centers = Object.keys(apiURLs);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [center, setCenter] = useState("");
  const [error, setError] = useState("");
  const [admin, setAdmin] = useState(props.admin || false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [videoLoad, setvideoLoad] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setAdmin(props.admin || false);
  }, [props.admin]);

  const makeLoginPostRequest = async (url, email, password, center, admin) => {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    formData.append("center", center);
    formData.append("admin", admin);
    const response = await axios.post(url, formData);
    return response.data;
  };

  const login = async (e) => {
    e.preventDefault();
    console.log("login", email, password, center, admin);
    setLoading(true);
    // check if email and password are empty
    if (email === "" || password === "") {
      setError("Please fill in all fields");
      return;
    }
    // check if center exists and user is not admin
    if (!admin && !centers.includes(center)) {
      setError("Please select a valid center");
      return;
    }
    // check if email is valid
    if (!email.includes("@")) {
      setError("Please enter a valid email");
      return;
    }
    // if user is admin get admin token from all centers
    if (admin) {
      // get admin token from all centers
      let numTokens = 0;
      try {
        for (const center of centers) {
          let response = await makeLoginPostRequest(
            apiURLs[center] + "/login",
            email,
            password,
            center,
            admin
          );
          const data = response;
          if (data.token) {
            // save token in local storage
            localStorage.setItem(`token-${data.center}`, data.token);
            numTokens++;
          } else {
            setError(`Invalid email or password for admin at ${data.center}`);
            // return;
          }
          localStorage.setItem("admin", admin);
          localStorage.setItem("center", centers[0]);
          localStorage.setItem("username", email);
          localStorage.setItem("load-labels", true);
        }
        if (numTokens === 0 || error) {
          setError("Invalid email or password for admin");
        } else {
          setLoggedIn(true);
        }
      } catch (error) {
        console.error("Error in login requests:", error);
      }
    } else {
      // if user is not admin get user token from selected center
      const url = apiURLs[center] + "/login";
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);
      formData.append("center", center);
      formData.append("admin", false);
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        console.log("response", data);
        // save token in local storage
        localStorage.setItem("token", data.token);
        localStorage.setItem("center", center);
        localStorage.setItem("admin", admin);
        localStorage.setItem("username", email);
        if (data.message === "password not reset") {
          setResetPassword(true);
        } else if (data.token) {
          setLoggedIn(true);
        } else {
          setError("Invalid email or password");
        }
      } else {
        setError("Invalid email or password");
      }
    }
    setLoading(false);
  };

  if (loggedIn) {
    const pathname = admin ? "/admin/jobs" : "/jobs";
    // navigate with props
    return <Navigate to={{ pathname: pathname }} />;
  }

  if (resetPassword) {
    return (
      <Navigate
        to={{
          pathname: "/password_reset",
          state: { email: email, center: center, admin: admin },
        }}
      />
    );
  }

  function videoLoaded() {
    setvideoLoad(false);
  }

  return (
    <div style={styles.container}>
      <div style={styles.videoContainer} className="videoContainer">
        {/* {videoLoad && (
          <div style={styles.loading}>
            <CircularProgress />
          </div>
        )}

        <video
          autoPlay
          muted
          loop
          src={videoBg}
          style={styles.video}
          onLoadedData={() => {
            videoLoaded();
          }}
        >
          <p>sorry your browser does'nt support embedded videos</p>
        </video> */}
        <Scene />
      </div>
      <div style={styles.loginContainer}>
        <img src={logo} alt="Urologiq Logo" height={100} />

        {!props.admin ? (
          // select center
          <TextField
            style={styles.center}
            label="Center"
            variant="outlined"
            value={center}
            onChange={(e) => setCenter(e.target.value)}
            placeholder="Center"
          />
        ) : null}
        <TextField
          style={styles.email}
          label="E-mail Address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <TextField
          style={styles.password}
          label="Password"
          variant="outlined"
          value={password}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          style={styles.loginBtn}
          onClick={(e) => {
            // console.log('trying to login with ', email
            setError("");
            login(e);
          }}
        >
          Login
        </Button>

        {!props.admin ? (
          <div>
            Click{" "}
            <Link to="/admin/login" params={{ admin: true }}>
              here
            </Link>{" "}
            for admin login.
          </div>
        ) : (
          <div>
            Click{" "}
            <Link to="/login" params={{ admin: false }}>
              here
            </Link>{" "}
            for user login.
          </div>
        )}
        {error && <div style={styles.error}>{error}</div>}
        {loading && <CircularProgress />}
      </div>
    </div>
  );
}
export default Login;
